body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-height{
  height: calc(100vh - 60px);
}

.pie-width{
  width: 50%
}

@media screen and (max-width: 1200px) {
  .pie-width{
    width: 85%
  }
}


.hide-on-desktop{
  display: none
}

@media screen and (max-width: 1200px) {
  .hide-on-desktop{
    display: block
  }
}



.bg-form{
  background-image: url("/public/form-bg\ -\ filtered.jpg");
  background-size: cover;
}

.bg-theme{
  background: #E5904F;
}

.bg-theme-light{
  background: #FFF2E7;
}

.absolute-center{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-bottom{
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button-0{
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}

.square{
  height: 20px;
  width: 20px;
  background-color: #f00;
}

.unpressed-button{
  background-color: #FFF2E7;
  border: solid 0px #774A26;
  border-radius: 15px;
  width: 4rem;
  height: 4rem;
  box-shadow: #774A26 1.5px 1.5px 5px 0px, #fff -1.5px -1.5px 5px 0px, inset #774A26 -1.5px -1.5px 5px 0px, inset #fff 1.5px 1.5px 5px 0px;

}

.pressed-button{
  background-color: #FFF2E7;
  border: solid 0px #FFF2E7;
  border-radius: 15px;
  width: 4rem;
  height: 4rem;
  box-shadow: #fff 1.5px 1.5px 5px 0px, #774A26 -1.5px -1.5px 5px 0px, inset #fff -1.5px -1.5px 5px 0px, inset #774A26 1.5px 1.5px 5px 0px;
}

.custom-radio{
  border: 0.5px solid #E5904F;
  transition: all 0.3;
}

.custom-radio:checked{
  background-color: #E5904F;
  border-color: #E5904F;
  transition: all 0.3;
  visibility: visible;

}

.custom-radio:checked:after{
  width: 100%;
  height: 100%;
  border-radius: 15px;
  top: -25%;
  position: relative;
  background-color: #E5904F;
  content: '';
  display: inline-block;
  visibility: visible;

}

.custom-radio:focus{
  box-shadow: none;
}

.custom-radio:active{
  background-color: #FFF2E7;
  border-color: #FFF2E7;
}


.outline-theme {
  background-color: transparent;
  color: #E5904F;
  border-color: #E5904F;
}

.outline-theme:hover {
  background-color: #E5904F;
  border-color: #E5904F;
  color: #fff;
  box-shadow: #e5904fae 0px 0px 10px 2px;
  transition: all 0.3;
}

.bottom-center{
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-light-select{
  color: #c9c9c9;
}