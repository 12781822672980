


.my-form{
  height: calc(100vh - 230px);
  width: calc(100vw - 400px);
  margin: 75px 200px;
  padding: 0;
  border-radius: 0 !important;
}

.menu{
  box-shadow: hsla(217, 84%, 62%, 0.2) 2px 4px 15px 2px;
  background-color: #fff;
}


.text-dark-blue{
  color: rgb(19, 72, 146);
  font-weight: 600;
  font-size: 11pt;
}
